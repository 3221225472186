import React from "react";
import { useTranslation } from "react-i18next";

const IHealthExtensionBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="ihealth-expiration">
      <h3 class="ihealth-header">{t("expiration-h")}</h3>
      <p>
        {t("expiration-p")}<br></br>
        <a
          href="https://ihealthlabs.com/pages/news#expiration"
          target="_blank"
          rel="noreferrer"
        >
          {t("expiration-link-ihealth")}
        </a>
        {" | "}
        <a
          href="https://www.fda.gov/media/158003/download"
          target="_blank"
          rel="noreferrer"
        >
          {t("expiration-link-abbott")}
        </a>

      </p>
    </div>
  );
};

export default IHealthExtensionBanner;