import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import ACTmap from "../images/maps/act-map.png";
import SYCTmap from "../images/maps/syct-mar-2024.png";

import arLogo from "../images/logos/states/ar.png";
import ilLogo from "../images/logos/states/il.jpg";
import meLogo from "../images/logos/states/me.jpg";
import miLogo from "../images/logos/states/mi.png";
import nmLogo from "../images/logos/states/nm.jpg";
import ohLogo from "../images/logos/states/oh.png";
import waLogo from "../images/logos/states/wa.jpg";
import wiLogo from "../images/logos/states/wi.png";
import mnLogo from "../images/logos/states/mn.png";
import ksLogo from "../images/logos/states/ks.png";
import ncLogo from "../images/logos/states/nc.png";
const ksPhone = "866-534-3463";
const ncPhone = "888-892-1162";

const map = process.env.REACT_APP_SITE_BRAND === "act" ? ACTmap : SYCTmap;
const localJson =
  window.location.protocol + "//" + window.location.host + "/data.json";

export default function SearchModule() {
  const { t, i18n } = useTranslation();
  const [zip, setZip] = useState(null);
  const [state, setState] = useState(null);
  const [logo, setLogo] = useState(null);
  const [wait, setWait] = useState(null);
  const [phone, setPhone] = useState(null);

  var orderFullUrl = process.env.REACT_APP_ORDER_FULL_URL;
  var configBaseUrl = process.env.REACT_APP_CONFIG_BASE_URL;
  var projectCode = process.env.REACT_APP_SITE_BRAND;
  var dtcOrderFormUrl = `${orderFullUrl}?lang=${i18n.resolvedLanguage}`;
  var dtcConfigUrl = `${configBaseUrl}/${projectCode}/sites.json`;

  var lastZip;
  async function validateZip() {
    var userZip = document.querySelector(".zip-search").value;
    if (userZip !== lastZip && userZip.length === 5) {
      lastZip = userZip;
      await searchZip(userZip);
    }
  }
  async function onClickSearch() {
    var userZip = document.querySelector(".zip-search").value;
    if (userZip.length === 5) {
      await searchZip(userZip);
    }
  }
  function closeModal() {
    document.querySelectorAll(".modal-overlay").forEach(function (el) {
      el.classList.add("hide");
    });
  }

  async function getJsonPromise(url) {
    var res = await fetch(url, { cache: "no-store" });
    var data = await res.json();
    return data;
  }

  async function searchZip(userZip) {
    async function tryDtcOrderForm() {
      var siteData = await getJsonPromise(dtcConfigUrl);
      if (!siteData || !siteData.zips) {
        return {
          error: true,
          errorType: "no site data",
        };
      }
      if (!siteData.zips.hasOwnProperty(userZip)) {
        return {
          error: true,
          errorType: "zip not in db",
        };
      }
      return siteData;
    }
    async function getLocalData() {
      var greaterStuff = await getJsonPromise(localJson);
      if (!greaterStuff || !greaterStuff.healthDepts) return false;
      var foundRow = greaterStuff.healthDepts.find(function (row) {
        if (row.zip === userZip) {
          return true;
        }
        return false;
      });
      if (!foundRow) return false;
      return foundRow.state;
    }
    async function getLogo() {
      switch (stateFromZip) {
        case "AR":
          return arLogo;
        case "IL":
          return ilLogo;
        case "ME":
          return meLogo;
        case "MI":
          return miLogo;
        case "NM":
          return nmLogo;
        case "OH":
          return ohLogo;
        case "WA":
          return waLogo;
        case "WI":
          return wiLogo;
        case "MN":
          return mnLogo;
        case "KS":
          return ksLogo;
        case "NC":
          return ncLogo;
        default:
          return "";
      }
    }
    async function getPhone() {
      switch (stateFromZip) {
        case "KS":
          return ksPhone;
        case "NC":
          return ncPhone;
        default:
          return "";
      }
    }
    function showZipInDb(cells) {
      setZip(cells.zip);
      setState(cells.state);
      setLogo(cells.logo);
      setWait(cells.wait);
      setPhone(cells.phone);

      function openTestModal() {
        if (document.querySelector(".get-tests")) {
          document.querySelector(".get-tests").classList.remove("hide");
        }
      }
      openTestModal();
      document.querySelector(".search-button").onclick = function () {
        openTestModal();
      };
      return true;
    }

    function showNoTests() {
      function openTestModal() {
        document.querySelector("#no-tests").classList.remove("hide");
      }
      openTestModal();
      document.querySelector(".search-button").onclick = function () {
        openTestModal();
      };
      return true;
    }

    var siteData = await tryDtcOrderForm();
    // if (siteData.error){
    //     console.log(siteData.errorType)
    // }
    var stateFromZip = await getLocalData();
    var logoFromState = await getLogo();
    var phoneFromState = await getPhone();

    if (siteData.zips) {
      showZipInDb({
        zip: userZip,
        state: stateFromZip,
        logo: logoFromState,
        phone: phoneFromState,
        dtcForm: true,
        wait: !siteData.zips[userZip] ? "yes" : false,
      });
    } else {
      showNoTests();
    }
  }

  const ZipInDb = () => {
    if (wait === "yes") {
      return (
        <div className="col-text center">
          {logo !== "" && (
            <img
              className="dept-logo"
              src={logo}
              alt="health department logo"
            />
          )}
          {state === "placeholder" ? (
            <>
              <h1 className="center">{t("search-try-again-h")}</h1>
              <p>{t("search-try-again-p")}</p>
            </>
          ) : (
            <>
              <h1 className="center">{t("search-thanks-h")}</h1>
              <p>{t("search-thanks-p01")}</p>
            </>
          )
          }
        </div>
      );
    }

    // if (wait === "come-back-later" ) {
    //     return (
    //         <div className="col-text center">
    //             <h1 className="center">{t('search-try-again-h')}</h1>
    //             {logo !== "" && (
    //                 <img className="dept-logo" src={logo} alt="health department logo" />
    //             )}
    //             <p>{t('search-try-again-p')}</p>
    //         </div>
    //     );
    // }

    return (
      <div className="col-text center">
        {logo !== "" && (
          <img className="dept-logo" src={logo} alt="health department logo" />
        )}
        <h1 className="center">{t("congratulations")}</h1>
        <p>
          <strong>{zip}</strong> {t("search-zip-eligible")}
        </p>
        <p>{t("search-signup-mail")}:</p>
        <div className="button order-button">
          <a href={dtcOrderFormUrl} target="_blank" rel="noreferrer">
            {t("send-me-my-tests")}
          </a>
        </div>
        {phone !== "" && (
          <p style={{ paddingTop: "20px" }}>
            <Trans i18nKey="phone-assistance">
              For ordering assistance, please call</Trans> {phone}.
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <section className="col-2" id="search-module">
          <div className="col-text center" style={{ maxWidth: "640px" }}>
            <h1>
              <Trans i18nKey="search-thanks-h">
                Thank you
              </Trans>
            </h1>
            <p>
              <Trans i18nKey="search-thanks-p01">{t("search-thanks-p01")}</Trans>
            </p>
          </div>

        <div className="center map">
          <img className="" src={map} alt="map" />
        </div>
      </section>
      <div id="no-tests" className="modal-overlay hide">
        <div className="modal-window">
          <div className="modal-header">
            <div className="modal-close" onClick={closeModal}>
              <i className="fas fa-times">
                <span className="sr-only">{t("close")}</span>
              </i>
            </div>
          </div>
          <div>
            <h2>{t("no-tests-available")}</h2>
            <p>{t("no-tests-available-p01")}</p>
            <p
              dangerouslySetInnerHTML={{ __html: t("no-tests-available-p02") }}
            ></p>
          </div>
        </div>
      </div>
      <div className="modal-overlay hide get-tests">
        <div className="modal-window eligible">
          <div className="modal-header">
            <div className="modal-close" onClick={closeModal}>
              <i className="fas fa-times">
                <span className="sr-only">{t("close")}</span>
              </i>
            </div>
          </div>
          {/* modal-header */}
          <ZipInDb />
        </div>
        {/* modal-window eligible */}
      </div>
      {/* modal-overlay hide get-tests */}
    </>
  );
}
