const languageNaming = {
  Amharic: "ኣማርኛ",
  Arabic: "العربية",
  Burmese: " မြန်မာစာ",
  Cambodian: "ភាសាខ្មែរ",
  "Chinese (Simplified)": "汉语",
  "Chinese (Traditional)": "漢語",
  Chuukese: "Chuukese",
  English: "English",
  Farsi: "فارسی",
  French: "Français",
  German: "Deutsch",
  Hindi: "हिन्दी",
  Hmong: "lus Hmoob",
  Japanese: "日本語",
  Karen: "Karen",
  Korean: "한국어",
  Lao: "ພາສາລາວ",
  Marshallese: "Kajin M̧ajeļ",
  "Mixteco-Bajo": "Mixteco-Bajo",
  Nepali: "नेपाली",
  Oromo: "Afaan Oromoo",
  Pashto: "پښتو",
  Portuguese: "Português",
  Punjabi: "पंजाबी",
  Romanian: "Română",
  Russian: "Русский",
  Samoan: "Gagana Sāmoa",
  Somali: "Somali",
  Spanish: "Español",
  Swahili: "Kiswahili",
  Tagalog: "Tagalog",
  Tamil: "தமிழ்",
  Telugu: "తెలుగు",
  Thai: "ภาษาไทย",
  Tigrinya: "ትግርኛ",
  Ukrainian: "Українська",
  Urdu: "اُردُو",
  Vietnamese: "Tiếng Việt Nam",
};

export default languageNaming;
